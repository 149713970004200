import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  Button,
  ListItemText,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMapImage } from "../../../hooks/useMapImage";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import EmailContent from "./generateHtml";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase";
import MuteButton from "./muteEventDrops1";

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: ({ darkMode }) => (darkMode ? "#121212" : "#FFFFFF"),
    color: ({ darkMode }) => (darkMode ? "#E7E7E7" : "#3C3C3C"),
    borderRadius: 16,
    maxWidth: 1000,
    width: "100%",
    margin: "auto",
    marginBottom: theme.spacing(3),
    border: "1px solid #7E7E7E",
    fontFamily: "'Inter', sans-serif",
    padding: "2px",
  },
  content: {
    padding: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    fontFamily: "'Inter', sans-serif",
    color: ({ darkMode }) => (darkMode ? "#E7E7E7" : "#3C3C3C"),
  },
  subtitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(0.8),
    fontFamily: "'Inter', sans-serif",
    color: ({ darkMode }) => (darkMode ? "#E7E7E7" : "#3C3C3C"),
  },
  icon: {
    marginRight: theme.spacing(1),
    color: ({ darkMode }) => (darkMode ? "#E7E7E7" : "#3C3C3C"),
  },
  sectionTitle: {
    color: "#0094FF",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontFamily: "'Inter', sans-serif",
  },
  phantomTitle: {
    color: "#6C3D8A",
    fontWeight: "bold",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    fontFamily: "'Inter', sans-serif",
  },
  releaseTime: {
    color: theme.palette.type === "dark" ? "#E7E7E7" : "#000000",
    fontSize: 14,
    textAlign: "left",
    fontFamily: "'Inter', sans-serif",
    marginBottom: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
  },
  rightColumnContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
  },
  mapContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: "-20px",
    marginBottom: theme.spacing(2),
    userSelect: "none",
  },
  mapImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    borderRadius: "10px",
    userSelect: "none",
    pointerEvents: "none",
  },
  marketplaceButton: {
    width: "68px",
    height: "35px",
    borderRadius: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  buttonContainer: {
    display: "flex",
    marginTop: theme.spacing(2),
    justifyContent: "flex-start",
    paddingLeft: theme.spacing(0),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(-2),
  },
  qPing: {
    background:
      "linear-gradient(90deg, rgb(215, 174, 251) 0%, rgb(142, 68, 173) 100%)",
    borderRadius: "8px",
    marginBottom: "8px",
    padding: "5px 10px",
    minHeight: "85px",
    "& .MuiListItemText-primary": {
      color: "black",
      fontFamily: "'Inter', sans-serif",
    },
  },
  qPingDarkMode: {
    background:
      "linear-gradient(90deg, rgb(215, 174, 251) 0%, rgb(142, 68, 173) 100%)",
    borderRadius: "8px",
    marginBottom: "8px",
    padding: "5px 10px",
    minHeight: "85px",
    "& .MuiListItemText-primary": {
      color: "black",
      fontFamily: "'Inter', sans-serif",
    },
  },
  standardAdmissionContainer: {
    borderRadius: "8px",
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(-1),
    cursor: "pointer",
    display: "inline-block",
    minWidth: "min-content",
    maxWidth: "100%",
  },
  standardAdmissionDark: {
    backgroundColor: "#5252524D",
    border: "1px solid #5E5E5E",
  },
  standardAdmissionLight: {
    backgroundColor: "#52525233",
    border: "1px solid #5E5E5E66",
  },
  earlyMonitor: {
    backgroundColor: "transparent",
    color: "black",
    borderRadius: 16,
    maxWidth: 1000,
    width: "100%",
    margin: "auto",
    marginBottom: theme.spacing(3),
    border: "1px solid #7E7E7E",
    fontFamily: "'Inter', sans-serif",
    padding: "2px",
  },
}));

const getStubhubLevelPrices = (stubhubData) => {
  if (!stubhubData?.total_data) return null;

  const levelPrices = {};

  for (const category in stubhubData.total_data) {
    const sections = stubhubData.total_data[category];
    const minArray = sections
      .map((section) => {
        const minValue = parseFloat(section.min?.replace(/[$,]/g, ""));
        return !isNaN(minValue) && minValue !== 0 ? minValue : null;
      })
      .filter((min) => min !== null);

    if (minArray.length > 0) {
      levelPrices[category] = `$${Math.min(...minArray)}`;
    }
  }

  return levelPrices;
};

const getTicketVendor = (eventLink) => {
  if (!eventLink) return { name: "N/A", color: "#0094FF" };

  if (eventLink.includes("ticketmaster"))
    return { name: "Ticketmaster", color: "#026CDF" };
  if (eventLink.includes("livenation"))
    return { name: "Live Nation", color: "#E41D39" };
  if (eventLink.includes("seatgeek"))
    return { name: "Seatgeek", color: "#FF5B49" };
  if (eventLink.includes("stubhub"))
    return { name: "Stubhub", color: "#3F1D74" };
  if (eventLink.includes("axs.com")) return { name: "Axs", color: "#1D549D" };
  if (eventLink.includes("mlb.tickets.com"))
    return { name: "MLB", color: "#bf0d3e" };
  if (eventLink.includes(".tickets.com") || eventLink.includes("tickets.com"))
    return { name: "Tickets.com", color: "#bf0d3e" };

  return { name: "N/A", color: "#0094FF" };
};

const isValidPrice = (price) => {
  if (!price) return false;
  const numPrice =
    typeof price === "string"
      ? parseFloat(price.replace(/[^0-9.-]+/g, ""))
      : price;
  return !isNaN(numPrice) && numPrice > 0;
};

const handleStubhubDragStart = (e, stubhubId) => {
  e.stopPropagation();
  const stubhubUrl = `https://www.stubhub.com/event/${stubhubId}`;
  e.dataTransfer.setData("text/uri-list", stubhubUrl);
  e.dataTransfer.setData("text/plain", stubhubUrl);
};

const handleVividDragStart = (e, vividUrl) => {
  e.stopPropagation();
  e.dataTransfer.setData("text/uri-list", vividUrl);
  e.dataTransfer.setData("text/plain", vividUrl);
};

const EventDetailCard = React.memo(
  ({
    event,
    darkMode,
    isLowStock,
    handleOverlayOpen,
    handleOverlayClose,
    filtersDocument,
    notesDocument,
    mainUser,
    early,
    totalEarly,
    vividIds,
    handleFilterClick,
    setCurrentEventId,
    setNotesModalOpen,
  }) => {
    const [localOverlayOpen, setLocalOverlayOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const vendorColor = getTicketVendor(event.eventLink).color;
    const classes = useStyles({ darkMode });
    const { mapImage, isLoading, retry } = useMapImage(
      event.map_url,
      event.eventLink
    );
    const theme = useTheme();
    const [stubhubData, setStubhubData] = useState(null);
    const [phantomGetIn, setPhantomGetIn] = useState("N/A");

    const isMlb = event.eventLink?.includes("mlb.tickets.com");
    const isAxs = event.eventLink?.includes("axs.com");
    const isQueueOpened = event.qPing;

    useEffect(() => {
      if (!event.stubhubId) return;

      const stubhubDocRef = doc(db, "stubhubData", String(event.stubhubId));
      const unsubscribe = onSnapshot(stubhubDocRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          let stubhubMin = Infinity;

          if (data.total_data) {
            for (const sectionName in data.total_data) {
              const section = data.total_data[sectionName];
              for (const item of section) {
                if (item.min !== undefined) {
                  const minPrice = parseFloat(
                    item.min.replace("$", "").replace(",", "")
                  );
                  if (
                    !isNaN(minPrice) &&
                    minPrice < stubhubMin &&
                    minPrice !== 0
                  ) {
                    stubhubMin = minPrice;
                  }
                }
              }
            }
          }

          setStubhubData(data);
          setPhantomGetIn(stubhubMin === Infinity ? "N/A" : `$${stubhubMin}`);
        }
      });

      return () => unsubscribe();
    }, [event.stubhubId]);

    const handleLocalOverlayOpen = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setLocalOverlayOpen(true);
    };

    const handleLocalOverlayClose = () => {
      setLocalOverlayOpen(false);
    };

    const handleCardClick = (e) => {
      e.preventDefault();
      if (!localOverlayOpen) {
        let eventLinkHref = event.eventLink;
        if (eventLinkHref.includes("ticketmaster.com")) {
          eventLinkHref = eventLinkHref.replace(
            "ticketmaster.com",
            "www.ticketmaster.com"
          );
        } else if (eventLinkHref.includes("ticketmaster.ca")) {
          eventLinkHref = eventLinkHref.replace(
            "ticketmaster.ca",
            "www.ticketmaster.ca"
          );
        }
        window.open(eventLinkHref, "_blank");
      }
    };

    const handleDragStart = (e) => {
      let eventLinkHref = event.eventLink;
      if (eventLinkHref.includes("ticketmaster.com")) {
        eventLinkHref = eventLinkHref.replace(
          "ticketmaster.com",
          "www.ticketmaster.com"
        );
      } else if (eventLinkHref.includes("ticketmaster.ca")) {
        eventLinkHref = eventLinkHref.replace(
          "ticketmaster.ca",
          "www.ticketmaster.ca"
        );
      }
      e.dataTransfer.setData("text/uri-list", eventLinkHref);
      e.dataTransfer.setData("text/plain", eventLinkHref);
    };

    const renderPhantomData = () => {
      if (!event.stubhubId || !stubhubData) return null;

      const paddingLeft = event.early || isLowStock ? "10px" : "2px";

      return (
        <>
          <Typography
            variant="h6"
            className={classes.phantomTitle}
            style={{
              color: "#6C3D8A",
              paddingLeft: paddingLeft,
            }}
          >
            Phantom Data
          </Typography>
          <Box
            mt={1}
            style={{
              lineHeight: "1.75",
              paddingLeft: paddingLeft,
            }}
          >
            <Typography
              variant="body1"
              style={{
                color: darkMode ? "#E7E7E7" : "#000000",
              }}
            >
              Get In: <strong>{phantomGetIn}</strong>
            </Typography>
            {stubhubData?.total_data && (
              <Typography
                variant="body1"
                style={{
                  color: darkMode ? "#E7E7E7" : "#000000",
                }}
                dangerouslySetInnerHTML={{
                  __html: Object.entries(
                    getStubhubLevelPrices(stubhubData) || {}
                  )
                    .map(
                      ([level, price]) =>
                        `${level.replace("Level", "Lvl")}: ` +
                        `<strong>${price}</strong>`
                    )
                    .join(", "),
                }}
              />
            )}
          </Box>
        </>
      );
    };

    return (
      <Card
        key={`${event.id || event.uniqueId}-${
          event.qPing ? "queue" : "normal"
        }`}
        className={`${
          event.qPing
            ? darkMode
              ? classes.qPingDarkMode
              : classes.qPing
            : event.early || isLowStock
            ? classes.earlyMonitor
            : darkMode
            ? classes.card
            : classes.card
        }`}
        style={{
          backgroundColor: event.qPing
            ? "transparent"
            : event.early || isLowStock
            ? "transparent"
            : darkMode
            ? "#121212"
            : "#FFFFFF",
          color:
            event.qPing || event.early || isLowStock
              ? "black"
              : darkMode
              ? "#E7E7E7"
              : "#3C3C3C",
          border: "1px solid #7E7E7E",
          cursor: "pointer",
          margin: "10px 0",
          padding: event.early || isLowStock ? "0" : "4px",
          position: "relative",
          overflow: "hidden",
          zIndex: "auto",
        }}
        onClick={handleCardClick}
        draggable="true"
        onDragStart={handleDragStart}
      >
        {event.qPing ? (
          <>
            <ListItemText
              style={{
                paddingLeft: "16px",
                paddingTop: "4px",
              }}
              primary={
                <>
                  {`${event.name} - ${event.venue}, ${event.date} `}
                  <div
                    style={{
                      marginTop: "2px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="/Queue-logo.png"
                      alt="Q-Tickets"
                      style={{
                        height: "25px",
                        width: "auto",
                        verticalAlign: "middle",
                        marginRight: "10px",
                        display: "inline-block",
                      }}
                    />
                    <strong>QUEUE OPENED</strong>
                    <img
                      src="/Queue-logo.png"
                      alt="Q-Tickets"
                      style={{
                        height: "25px",
                        width: "auto",
                        verticalAlign: "middle",
                        marginLeft: "10px",
                        display: "inline-block",
                      }}
                    />
                  </div>
                  <Typography
                    component="div"
                    style={{
                      color: "black",
                      fontSize: "inherit",
                      fontFamily: "'Inter', sans-serif",
                      marginTop: "2px",
                      fontWeight: "normal",
                    }}
                  >
                    Release Time: {event.releaseTime}
                  </Typography>
                </>
              }
            />
          </>
        ) : event.early || isLowStock ? (
          <div
            style={{
              width: "calc(100% + 8px)",
              margin: "-4px -4px 0 -4px",
              borderRadius: "16px",
            }}
          >
            <div
              style={{
                background: isLowStock
                  ? "linear-gradient(90deg, rgb(142, 68, 173) 0%, rgb(215, 174, 251) 100%)"
                  : "linear-gradient(90deg, #FFDE9C96 0%, #FFAA0096 100%)",
                padding: "16px",
                borderRadius: "15px",
                marginBottom: "8px",
                border: "1px solid #7E7E7E",
              }}
            >
              <Typography
                variant="h6"
                style={{
                  color: darkMode ? "#E7E7E7" : "#3C3C3C",
                  fontFamily: "'Inter', sans-serif",
                  fontWeight: "bold",
                }}
              >
                {`${event.name} (${event.quantity} tickets found)`}
              </Typography>
            </div>
            <CardContent
              className={classes.content}
              style={{ paddingLeft: "16px" }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography
                    variant="subtitle1"
                    className={classes.subtitle}
                    style={{
                      color: darkMode ? "#E7E7E7" : "#3C3C3C",
                      paddingLeft: "8px",
                    }}
                  >
                    <img
                      src={darkMode ? "/pin1.svg" : "/pin2.svg"}
                      alt="Location Icon"
                      className={classes.icon}
                    />
                    {event.venue}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className={classes.subtitle}
                    style={{
                      color: darkMode ? "#E7E7E7" : "#3C3C3C",
                      paddingLeft: "8px",
                    }}
                  >
                    <img
                      src={darkMode ? "/clock1.svg" : "/clock2.svg"}
                      alt="Time Icon"
                      className={classes.icon}
                    />
                    {event.date}
                  </Typography>

                  <div
                    className={`${classes.standardAdmissionContainer} ${
                      darkMode
                        ? classes.standardAdmissionDark
                        : classes.standardAdmissionLight
                    }`}
                    onClick={handleLocalOverlayOpen}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    role="button"
                    tabIndex={0}
                    style={{
                      marginLeft: "2px",
                      backgroundColor: isHovered
                        ? `${vendorColor}${darkMode ? "30" : "1A"}`
                        : darkMode
                        ? "#5252524D"
                        : "#52525233",
                      border: `1px solid ${
                        isHovered
                          ? vendorColor
                          : darkMode
                          ? "#5E5E5E"
                          : "#5E5E5E66"
                      }`,
                      transition:
                        "background-color 0.2s ease-in-out, border 0.2s ease-in-out",
                    }}
                  >
                    <Typography
                      variant="h6"
                      className={classes.sectionTitle}
                      style={{
                        color: getTicketVendor(event.eventLink).color,
                        marginTop: 0,
                      }}
                    >
                      {getTicketVendor(event.eventLink).name}
                    </Typography>
                    {isLowStock ? (
                      <Typography
                        variant="body1"
                        style={{
                          color: darkMode ? "#E7E7E7" : "#000000",
                          marginBottom: theme.spacing(1),
                        }}
                      >
                        {Array.isArray(event.groupTickets) &&
                          event.groupTickets
                            .slice(0, 2)
                            .map((ticket, index) => (
                              <div key={index} style={{ marginBottom: "8px" }}>
                                {ticket.name} Section {ticket.sectionName} -{" "}
                                {ticket.totalAvailableStock}
                              </div>
                            ))}
                      </Typography>
                    ) : Array.isArray(event.groupTickets) ? (
                      event.groupTickets.slice(0, 2).map((ticket, index) => {
                        return (
                          <Typography
                            key={index}
                            variant="body1"
                            style={{
                              color: darkMode ? "#E7E7E7" : "#000000",
                              marginBottom: theme.spacing(1),
                            }}
                          >
                            {ticket.name && `${ticket.name} - `}Section{" "}
                            {ticket.sectionName}
                            {ticket.sectionRow && `, Row ${ticket.sectionRow}`},
                            Seats{" "}
                            {ticket.seatNumbers
                              ? ticket.seatNumbers.join(", ")
                              : "N/A"}{" "}
                            <strong>
                              (
                              {ticket.seatNumbers
                                ? ticket.seatNumbers.length
                                : ticket.quantity}
                              )
                            </strong>
                            {isValidPrice(ticket.price) ? (
                              <>
                                {" - "}
                                <strong>
                                  {event.priceDrop || event.priceIncrease
                                    ? `$${ticket.previousPrice}➜${ticket.price}`
                                    : `$${ticket.price}`}
                                </strong>
                              </>
                            ) : null}
                          </Typography>
                        );
                      })
                    ) : (
                      <Typography
                        variant="body1"
                        style={{
                          color: darkMode ? "#E7E7E7" : "#000000",
                          marginBottom: theme.spacing(1),
                        }}
                      >
                        {event.type && `${event.type} - `}Section{" "}
                        {event.section}
                        {event.row && `, Row ${event.row}`}, Seats {event.seats}{" "}
                        <strong>({event.quantity})</strong>
                        {isValidPrice(event.price) ? (
                          <>
                            {" - "}
                            <strong>
                              {event.priceDrop || event.priceIncrease
                                ? `$${event.previousPrice}->${event.price}`
                                : `$${event.price}`}
                            </strong>
                          </>
                        ) : null}
                      </Typography>
                    )}
                    {Array.isArray(event.groupTickets) &&
                      event.groupTickets.length > 2 && (
                        <Typography
                          variant="body1"
                          style={{
                            color: darkMode ? "#E7E7E7" : "#000000",
                            marginBottom: theme.spacing(1),
                          }}
                        >
                          ...
                        </Typography>
                      )}
                  </div>
                  {renderPhantomData()}
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box className={classes.rightColumnContainer}>
                    <Box className={classes.mapContainer}>
                      {isMlb ? (
                        <img
                          src="/mlb.png"
                          alt="MLB Map"
                          className={classes.mapImage}
                        />
                      ) : isAxs ? (
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/a/a7/Axs_logo.svg"
                          alt="AXS Logo"
                          className={classes.mapImage}
                          style={{
                            height: "180px",
                            width: "180px",
                            margin: "auto",
                          }}
                        />
                      ) : mapImage &&
                        typeof mapImage === "string" &&
                        mapImage.trim() !== "" ? (
                        <img
                          src={`data:image/png;base64,${mapImage}`}
                          alt="Event Map"
                          className={classes.mapImage}
                          onError={(e) => {
                            console.error(
                              "Image failed to load:",
                              e.target.src
                            );
                            e.target.onerror = null;
                            e.target.src =
                              "https://thehcpac.org/wp-content/uploads/2016/11/redticket.png";
                          }}
                        />
                      ) : mapImage !== null ? (
                        <img
                          src={event.map_url}
                          alt="Event Map"
                          className={classes.mapImage}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://thehcpac.org/wp-content/uploads/2016/11/redticket.png";
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            width: "390px",
                            height: "290px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            backgroundColor: darkMode ? "#121212" : "#FFFFFF",
                            borderRadius: "10px",
                          }}
                        >
                          {isLoading ? (
                            <>
                              <Box
                                sx={{
                                  position: "relative",
                                  display: "inline-flex",
                                  mb: 2,
                                }}
                              >
                                <CircularProgress
                                  size={40}
                                  thickness={4}
                                  sx={{
                                    color: darkMode ? "#E7E7E7" : "#3C3C3C",
                                  }}
                                />
                              </Box>
                              <Typography
                                variant="body2"
                                style={{
                                  color: darkMode ? "#E7E7E7" : "#3C3C3C",
                                  opacity: 0.8,
                                  fontFamily: "'Inter', sans-serif",
                                }}
                              >
                                Loading venue map...
                              </Typography>
                            </>
                          ) : (
                            <div style={{ textAlign: "center" }}>
                              <Typography
                                variant="body1"
                                style={{
                                  color: darkMode ? "#E7E7E7" : "#3C3C3C",
                                  marginBottom: "16px",
                                  fontFamily: "'Inter', sans-serif",
                                }}
                              >
                                Map not available
                              </Typography>
                              <Button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  retry();
                                }}
                                size="small"
                                variant="outlined"
                                sx={{
                                  borderColor: darkMode ? "#E7E7E7" : "#3C3C3C",
                                  color: darkMode ? "#E7E7E7" : "#3C3C3C",
                                  "&:hover": {
                                    borderColor: darkMode
                                      ? "#FFFFFF"
                                      : "#000000",
                                    backgroundColor: darkMode
                                      ? "rgba(255, 255, 255, 0.05)"
                                      : "rgba(0, 0, 0, 0.05)",
                                  },
                                }}
                              >
                                Retry Loading Map
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                    </Box>
                    <Box className={classes.buttonContainer}>
                      {event.stubhubId && (
                        <a
                          href={`https://www.stubhub.com/event/${event.stubhubId}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.marketplaceButton}
                          style={{ 
                            backgroundColor: "#3F1D74",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            userSelect: 'none'
                          }}
                          onClick={(e) => e.stopPropagation()}
                          draggable="true"
                          onDragStart={(e) => handleStubhubDragStart(e, event.stubhubId)}
                        >
                          <img
                            src="/stubhubsmall.svg"
                            alt="stubhub-seats-logo"
                            width="20px"
                            height="19px"
                            style={{ pointerEvents: 'none' }}
                          />
                        </a>
                      )}
                      {event.vividUrl && (
                        <a
                          href={event.vividUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classes.marketplaceButton}
                          style={{
                            backgroundImage: darkMode
                              ? "url(/vivid-blue.svg)"
                              : "none",
                            backgroundSize: "cover",
                            backgroundColor: darkMode ? "transparent" : "#000000",
                            width: "68px",
                            height: "35px",
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            userSelect: 'none'
                          }}
                          onClick={(e) => e.stopPropagation()}
                          draggable="true"
                          onDragStart={(e) => handleVividDragStart(e, event.vividUrl)}
                        >
                          <img
                            src="/vividsmall.svg"
                            alt="vivid-seats-logo"
                            width="20px"
                            height="17px"
                            style={{ pointerEvents: 'none' }}
                          />
                        </a>
                      )}
                    </Box>
                    <Typography
                      variant="caption"
                      className={classes.releaseTime}
                      style={{ color: darkMode ? "#E7E7E7" : "#000000" }}
                    >
                      <strong>Release Time:</strong> {event.releaseTime}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </div>
        ) : (
          <CardContent className={classes.content}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <Typography
                  variant="h5"
                  className={classes.title}
                  style={{
                    color: isQueueOpened
                      ? "black"
                      : darkMode
                      ? "#E7E7E7"
                      : "#3C3C3C",
                  }}
                >
                  {isQueueOpened ? (
                    <>
                      {`${event.name} - ${event.venue}, ${event.date} `}
                      <br />
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <img
                          src="/Queue-logo.png"
                          alt="Q-Tickets"
                          style={{
                            height: "25px",
                            width: "auto",
                            marginRight: "10px",
                          }}
                        />
                        <strong>QUEUE OPENED</strong>
                        <img
                          src="/Queue-logo.png"
                          alt="Q-Tickets"
                          style={{
                            height: "25px",
                            width: "auto",
                            marginLeft: "10px",
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    event.name
                  )}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.subtitle}
                  style={{ color: darkMode ? "#E7E7E7" : "#3C3C3C" }}
                >
                  <img
                    src={darkMode ? "/pin1.svg" : "/pin2.svg"}
                    alt="Location Icon"
                    className={classes.icon}
                  />
                  {event.venue}
                </Typography>
                <Typography
                  variant="subtitle1"
                  className={classes.subtitle}
                  style={{ color: darkMode ? "#E7E7E7" : "#3C3C3C" }}
                >
                  <img
                    src={darkMode ? "/clock1.svg" : "/clock2.svg"}
                    alt="Time Icon"
                    className={classes.icon}
                  />
                  {event.date}
                </Typography>

                <div
                  className={`${classes.standardAdmissionContainer} ${
                    darkMode
                      ? classes.standardAdmissionDark
                      : classes.standardAdmissionLight
                  }`}
                  onClick={handleLocalOverlayOpen}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  role="button"
                  tabIndex={0}
                  style={{
                    backgroundColor: isHovered
                      ? `${vendorColor}${darkMode ? "30" : "1A"}`
                      : darkMode
                      ? "#5252524D"
                      : "#52525233",
                    border: `1px solid ${
                      isHovered
                        ? vendorColor
                        : darkMode
                        ? "#5E5E5E"
                        : "#5E5E5E66"
                    }`,
                    transition:
                      "background-color 0.2s ease-in-out, border 0.2s ease-in-out",
                  }}
                >
                  <Typography
                    variant="h6"
                    className={classes.sectionTitle}
                    style={{
                      color: getTicketVendor(event.eventLink).color,
                      marginTop: 0,
                    }}
                  >
                    {getTicketVendor(event.eventLink).name}
                  </Typography>
                  {isLowStock ? (
                    <Typography
                      variant="body1"
                      style={{
                        color: darkMode ? "#E7E7E7" : "#000000",
                        marginBottom: theme.spacing(1),
                      }}
                    >
                      {Array.isArray(event.groupTickets) &&
                        event.groupTickets.slice(0, 2).map((ticket, index) => (
                          <div key={index} style={{ marginBottom: "8px" }}>
                            {ticket.name} Section {ticket.sectionName} -{" "}
                            {ticket.totalAvailableStock}
                          </div>
                        ))}
                    </Typography>
                  ) : Array.isArray(event.groupTickets) ? (
                    event.groupTickets.slice(0, 2).map((ticket, index) => {
                      return (
                        <Typography
                          key={index}
                          variant="body1"
                          style={{
                            color: darkMode ? "#E7E7E7" : "#000000",
                            marginBottom: theme.spacing(1),
                          }}
                        >
                          {ticket.name && `${ticket.name} - `}Section{" "}
                          {ticket.sectionName}
                          {ticket.sectionRow && `, Row ${ticket.sectionRow}`},
                          Seats{" "}
                          {ticket.seatNumbers
                            ? ticket.seatNumbers.join(", ")
                            : "N/A"}{" "}
                          <strong>
                            (
                            {ticket.seatNumbers
                              ? ticket.seatNumbers.length
                              : ticket.quantity}
                            )
                          </strong>
                          {isValidPrice(ticket.price) ? (
                            <>
                              {" - "}
                              <strong>
                                {event.priceDrop || event.priceIncrease
                                  ? `$${ticket.previousPrice}➜${ticket.price}`
                                  : `$${ticket.price}`}
                              </strong>
                            </>
                          ) : null}
                        </Typography>
                      );
                    })
                  ) : (
                    <Typography
                      variant="body1"
                      style={{
                        color: darkMode ? "#E7E7E7" : "#000000",
                        marginBottom: theme.spacing(1),
                      }}
                    >
                      {event.type && `${event.type} - `}Section {event.section}
                      {event.row && `, Row ${event.row}`}, Seats {event.seats}{" "}
                      <strong>({event.quantity})</strong>
                      {isValidPrice(event.price) ? (
                        <>
                          {" - "}
                          <strong>
                            {event.priceDrop || event.priceIncrease
                              ? `$${event.previousPrice}->${event.price}`
                              : `$${event.price}`}
                          </strong>
                        </>
                      ) : null}
                    </Typography>
                  )}
                  {Array.isArray(event.groupTickets) &&
                    event.groupTickets.length > 2 && (
                      <Typography
                        variant="body1"
                        style={{
                          color: darkMode ? "#E7E7E7" : "#000000",
                          marginBottom: theme.spacing(1),
                        }}
                      >
                        ...
                      </Typography>
                    )}
                </div>
                {renderPhantomData()}
              </Grid>

              <Grid item xs={12} md={4}>
                <Box className={classes.rightColumnContainer}>
                  <Box className={classes.mapContainer}>
                    {isMlb ? (
                      <img
                        src="/mlb.png"
                        alt="MLB Map"
                        className={classes.mapImage}
                      />
                    ) : isAxs ? (
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/a/a7/Axs_logo.svg"
                        alt="AXS Logo"
                        className={classes.mapImage}
                        style={{
                          height: "180px",
                          width: "180px",
                          margin: "auto",
                        }}
                      />
                    ) : mapImage &&
                      typeof mapImage === "string" &&
                      mapImage.trim() !== "" ? (
                      <img
                        src={`data:image/png;base64,${mapImage}`}
                        alt="Event Map"
                        className={classes.mapImage}
                        onError={(e) => {
                          console.error("Image failed to load:", e.target.src);
                          e.target.onerror = null;
                          e.target.src =
                            "https://thehcpac.org/wp-content/uploads/2016/11/redticket.png";
                        }}
                      />
                    ) : mapImage !== null ? (
                      <img
                        src={event.map_url}
                        alt="Event Map"
                        className={classes.mapImage}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src =
                            "https://thehcpac.org/wp-content/uploads/2016/11/redticket.png";
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          width: "390px",
                          height: "290px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                          backgroundColor: darkMode ? "#121212" : "#FFFFFF",
                          borderRadius: "10px",
                        }}
                      >
                        {isLoading ? (
                          <>
                            <Box
                              sx={{
                                position: "relative",
                                display: "inline-flex",
                                mb: 2,
                              }}
                            >
                              <CircularProgress
                                size={40}
                                thickness={4}
                                sx={{
                                  color: darkMode ? "#E7E7E7" : "#3C3C3C",
                                }}
                              />
                            </Box>
                            <Typography
                              variant="body2"
                              style={{
                                color: darkMode ? "#E7E7E7" : "#3C3C3C",
                                opacity: 0.8,
                                fontFamily: "'Inter', sans-serif",
                              }}
                            >
                              Loading venue map...
                            </Typography>
                          </>
                        ) : (
                          <div style={{ textAlign: "center" }}>
                            <Typography
                              variant="body1"
                              style={{
                                color: darkMode ? "#E7E7E7" : "#3C3C3C",
                                marginBottom: "16px",
                                fontFamily: "'Inter', sans-serif",
                              }}
                            >
                              Map not available
                            </Typography>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                retry();
                              }}
                              size="small"
                              variant="outlined"
                              sx={{
                                borderColor: darkMode ? "#E7E7E7" : "#3C3C3C",
                                color: darkMode ? "#E7E7E7" : "#3C3C3C",
                                "&:hover": {
                                  borderColor: darkMode ? "#FFFFFF" : "#000000",
                                  backgroundColor: darkMode
                                    ? "rgba(255, 255, 255, 0.05)"
                                    : "rgba(0, 0, 0, 0.05)",
                                },
                              }}
                            >
                              Retry Loading Map
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </Box>
                  <Box className={classes.buttonContainer}>
                    {event.stubhubId && (
                      <a
                        href={`https://www.stubhub.com/event/${event.stubhubId}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.marketplaceButton}
                        style={{ 
                          backgroundColor: "#3F1D74",
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          userSelect: 'none'
                        }}
                        onClick={(e) => e.stopPropagation()}
                        draggable="true"
                        onDragStart={(e) => handleStubhubDragStart(e, event.stubhubId)}
                      >
                        <img
                          src="/stubhubsmall.svg"
                          alt="stubhub-seats-logo"
                          width="20px"
                          height="19px"
                          style={{ pointerEvents: 'none' }}
                        />
                      </a>
                    )}
                    {event.vividUrl && (
                      <a
                        href={event.vividUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.marketplaceButton}
                        style={{
                          backgroundImage: darkMode
                            ? "url(/vivid-blue.svg)"
                            : "none",
                          backgroundSize: "cover",
                          backgroundColor: darkMode ? "transparent" : "#000000",
                          width: "68px",
                          height: "35px",
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          userSelect: 'none'
                        }}
                        onClick={(e) => e.stopPropagation()}
                        draggable="true"
                        onDragStart={(e) => handleVividDragStart(e, event.vividUrl)}
                      >
                        <img
                          src="/vividsmall.svg"
                          alt="vivid-seats-logo"
                          width="20px"
                          height="17px"
                          style={{ pointerEvents: 'none' }}
                        />
                      </a>
                    )}
                  </Box>
                  <Typography
                    variant="caption"
                    className={classes.releaseTime}
                    style={{ color: darkMode ? "#E7E7E7" : "#000000" }}
                  >
                    <strong>Release Time:</strong> {event.releaseTime}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        )}
        <Dialog
          open={localOverlayOpen}
          onClose={handleLocalOverlayClose}
          scroll="paper"
          fullWidth
          maxWidth="md"
          PaperProps={{
            style: {
              maxHeight: "100%",
              width: "90%",
              borderRadius: "20px",
              backgroundColor: darkMode ? "#222222" : "",
              color: darkMode ? "white" : "",
            },
            onClick: (e) => e.stopPropagation(),
          }}
          onDragStart={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <DialogContent
            dividers
            style={{
              backgroundColor: darkMode ? "#222222" : "white",
            }}
            onDragStart={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
          >
            <EmailContent
              event_info={{
                name: event.name,
                venue: event.venue,
                date: event.date,
                faceValueExchange: event.faceValueExchange,
                priceRange: event.priceRange,
              }}
              quantity={event.quantity}
              map_url={event.map_url}
              groupedTickets={event.groupTickets}
              eventLink={event.eventLink}
              timestamp={event.timestamp}
              filtersDocument={filtersDocument[event.id]}
              handleFilterClick={handleFilterClick}
              notesDocument={notesDocument}
              userEmail={mainUser}
              totalSeats={event.totalSeats}
              stubhub={event.stubhubId}
              eventId={event.id}
              early={early}
              totalEarly={totalEarly}
              showTable={true}
              editFilters={false}
              isLowStock={isLowStock}
              vividIds={vividIds}
              email={{
                ...event,
                ticketMasterUK: event.ticketMasterUK,
                eu: event.eu,
                eventId: event.id,
                eventUrl: event.eventLink,
                priceDrop: event.priceDrop,
                previousPrice: event.previousPrice,
                price: event.price,
                quantity: event.quantity,
                totalSeats: event.totalSeats,
                priceDrops: event.priceDrop,
              }}
              setCurrentEventId={setCurrentEventId}
              setNotesModalOpen={setNotesModalOpen}
              stubhubButton={(stubhubId) => (
                <div 
                  onDragStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const stubhubUrl = `https://www.stubhub.com/event/${stubhubId}`;
                    e.dataTransfer.setData("text/uri-list", stubhubUrl);
                    e.dataTransfer.setData("text/plain", stubhubUrl);
                  }}
                  onClick={(e) => e.stopPropagation()}
                  style={{ display: 'inline-block' }}
                >
                  <a
                    href={`https://www.stubhub.com/event/${stubhubId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.marketplaceButton}
                    style={{ 
                      backgroundColor: "#3F1D74",
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      userSelect: 'none'
                    }}
                    onClick={(e) => e.stopPropagation()}
                    draggable="true"
                  >
                    <img
                      src="/stubhubsmall.svg"
                      alt="stubhub-seats-logo"
                      width="20px"
                      height="19px"
                      style={{ pointerEvents: 'none' }}
                    />
                  </a>
                </div>
              )}
              vividButton={(vividUrl) => (
                <div
                  onDragStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.dataTransfer.setData("text/uri-list", vividUrl);
                    e.dataTransfer.setData("text/plain", vividUrl);
                  }}
                  onClick={(e) => e.stopPropagation()}
                  style={{ display: 'inline-block' }}
                >
                  <a
                    href={vividUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.marketplaceButton}
                    style={{
                      backgroundImage: darkMode ? "url(/vivid-blue.svg)" : "none",
                      backgroundSize: "cover",
                      backgroundColor: darkMode ? "transparent" : "#000000",
                      width: "68px",
                      height: "35px",
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      userSelect: 'none'
                    }}
                    onClick={(e) => e.stopPropagation()}
                    draggable="true"
                  >
                    <img
                      src="/vividsmall.svg"
                      alt="vivid-seats-logo"
                      width="20px"
                      height="17px"
                      style={{ pointerEvents: 'none' }}
                    />
                  </a>
                </div>
              )}
            />
            <div style={{ display: "flex", marginTop: "-38px" }}>
              <MuteButton
                eventId={event.id}
                user={mainUser}
                eventUrl={event.eventLink}
                totalEarly={totalEarly}
                early={early}
                mainUser={mainUser}
              />
            </div>
          </DialogContent>
        </Dialog>
      </Card>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.event.id === nextProps.event.id &&
      prevProps.event.uniqueId === nextProps.event.uniqueId &&
      prevProps.darkMode === nextProps.darkMode &&
      prevProps.isLowStock === nextProps.isLowStock &&
      prevProps.event.qPing === nextProps.event.qPing &&
      prevProps.event.early === nextProps.event.early &&
      prevProps.event.timestamp === nextProps.event.timestamp &&
      JSON.stringify(prevProps.event.groupTickets) ===
        JSON.stringify(nextProps.event.groupTickets)
    );
  }
);

export default EventDetailCard;
