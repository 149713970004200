export function isArrayEqual(a, b) {
  if (!Array.isArray(a) || !Array.isArray(b)) return false;

  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  a.sort();
  b.sort();

  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }

  return true;
}

export function fetchData(
  eventId,
  tableState,
  setTableState,
  defaultData,
  contextFilters
) {
  if (!contextFilters) return null;

  try {
    const filters = contextFilters[eventId];

    if (!filters) {
      setTableState({ data: [defaultData], filtersApplied: false });
      return [defaultData];
    }

    if (Object.keys(filters).length === 0) {
      setTableState({ filtersApplied: false });
      return tableState.data;
    }

    const filtersCopy = JSON.parse(JSON.stringify(filters));

    const sortedKeys = Object.keys(filtersCopy).sort((a, b) => {
      const numA = parseInt(a.substring(3));
      const numB = parseInt(b.substring(3));
      return numA - numB;
    });

    const sortedData = sortedKeys.map((key) => {
      if (typeof filtersCopy[key].prices === "string") {
        filtersCopy[key].prices = [{ min: 0, max: filtersCopy[key].prices }];
      }

      return filtersCopy[key];
    });

    setTableState({
      data: sortedData,
      selectedPaths: sortedData[0]?.sections ?? [],
      filtersApplied: true,
    });

    return sortedData;
  } catch (error) {
    console.error("Error fetching data: ", error);
    return null;
  }
}
